import Icons from "./icons";
import config from "./config";
import intlTelInput from "intl-tel-input";
import "./styles.css";
import "./custom.css";
import flags from "./flags.png";
import flags2x from "./flags@2x.png";

const API_WEBSITE = `${config.baseAPIURL}/${config.prefix}/website`;
const API_VERIFICATION = `${config.baseAPIURL}/${config.prefix}/verification`;

let dialCode = "1";

function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  switch (dialCode) {
    case "44":
      if (phoneNumberLength < 5) return phoneNumber;
      else if (phoneNumberLength < 8) {
        return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
      } else {
        return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4, 11)}`;
      }
    case "1":
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    case "49":
      if (phoneNumberLength < 3) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    case "33":
      if (phoneNumberLength < 3) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    case "34":
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
    case "61":
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 6) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} ${phoneNumber.slice(6, 10)}`;
    case "353":
      if (phoneNumberLength < 3) return phoneNumber;
      if (phoneNumberLength < 6) {
        return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2)}`;
      }
      return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
        2,
        5
      )} ${phoneNumber.slice(5, 10)}`;
    default:
      if (phoneNumberLength < 4) return phoneNumber;
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )}-${phoneNumber.slice(6, 10)}`;
  }
}

function phoneNumberFormatter() {
  const phoneInputField = document.querySelector("#phoneTel");
  phoneInputField.value = formatPhoneNumber(phoneInputField.value);
}

function handleInputChange() {
  const phoneInputField = document.querySelector("#phoneTel");
  const btnSignIn = document.getElementById("submit");
  phoneInputField.value = formatPhoneNumber(phoneInputField.value);
  const { value } = phoneInputField;
  btnSignIn.disabled = value.replace(/\(|\)|-|\s/g, "").length < 9;
}

function revertPhoneFormatToString(phone) {
  return phone.replace(/\(|\)|-|\s/g, "");
}

function addJsForPhoneTelInput() {
  const phoneInputField = document.querySelector("#phoneTel");

  const inputIntlTelInput = intlTelInput(phoneInputField, {
    initialCountry: "us",
  });

  const rect = phoneInputField.getBoundingClientRect();
  // eslint-disable-next-line no-undef
  const style = document.createElement("style");

  style.textContent = `
           @media screen and (max-width: 720px) {
                 .iti-mobile .iti--container {
                      top: ${rect.top + 55}px;
                      left: ${rect.left}px;
                }
              }
          `;
  // eslint-disable-next-line no-undef
  document.head.appendChild(style);

  phoneInputField.addEventListener("countrychange", function () {
    // eslint-disable-next-line no-undef
    dialCode = inputIntlTelInput.getSelectedCountryData().dialCode;
    // eslint-disable-next-line no-undef
    phoneInputField.value = formatPhoneNumber(phoneInputField.value);
  });

  document
    .getElementById("phoneTel")
    .addEventListener("focus", () => phoneNumberFormatter());
  document
    .getElementById("phoneTel")
    .addEventListener("keydown", () => phoneNumberFormatter());
  document
    .getElementById("phoneTel")
    .addEventListener("keyup", () => phoneNumberFormatter());
  document
    .getElementById("phoneTel")
    .addEventListener("paste", () => phoneNumberFormatter());
  document
    .getElementById("phoneTel")
    .addEventListener("input", () => handleInputChange());
}

const VerificationStatus = {
  IN_PROGRESS: "pending",
  VERIFIED: "verified",
  FAILED: "failed",
};

function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

class Dentity {
  domainConfig;
  numberEntered;
  isRetry;

  constructor(
    config = {
      element: String,
      key: String,
    }
  ) {
    this.config = config;
    this.eventMap = new Map();
    this.init();
    this.domainConfig = {};
    this.numberEntered = "";
    this.isRetry = false;
  }

  setKey(key) {
    this.config.key = key;
  }

  init() {
    const self = this;
    document.querySelectorAll(self.config.element).forEach((element) => {
      element.addEventListener("click", function () {
        self.handleShowModal();
        addJsForPhoneTelInput();
      });
    });
    // Get config websites to display custom verification
    if (!this.domainConfig) {
      this.getConfigWebsite().then((result) => {
        this.insertModalToDOM(result.data);
        this.domainConfig = result.data;
        this.dispatchReady();
      });
    }
    if (mobileCheck()) {
      window.addEventListener("touchstart", function (event) {
        const tooltip = this.document.querySelector(".kyc-tooltip-icon");
        if (
          event.target !== tooltip &&
          tooltip &&
          this.document.activeElement === tooltip
        ) {
          tooltip.blur();
        }
      });
    }

    const style = document.createElement("style");

    style.textContent = `
        .iti__flag {
          background-image: url(${config.CDN_LINK}/${flags});
        }
        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
          .iti__flag {
           background-image: url(${config.CDN_LINK}/${flags2x});
          }
        }
    `;
    document.head.appendChild(style);
  }

  // Handle input phone number
  inputHandler(e) {
    e.preventDefault();
    const phoneNumber = document.querySelector("input[name='phoneNumber']");
    const submitBtn = document.querySelector("#submit");
    phoneNumber.value = phoneNumber.value
      .replace(/^0+/, "")
      .replace(/[^0-9]/g, "");

    if (phoneNumber.value.length >= 9) {
      if (submitBtn) {
        submitBtn.disabled = false;
      }
    } else {
      if (submitBtn) {
        submitBtn.disabled = true;
      }
    }
  }

  onMobileInputHandler(e) {
    e.preventDefault();
    const onMobilePhoneNumber = document.querySelector(
      "input[name='onMobilePhoneNumber']"
    );
    const mobileSubmitBtn = document.querySelector("#mobileSubmit");
    onMobilePhoneNumber.value = onMobilePhoneNumber.value
      .replace(/^0+/, "")
      .replace(/[^0-9]/g, "");

    if (onMobilePhoneNumber.value.length >= 9) {
      if (mobileSubmitBtn) {
        mobileSubmitBtn.disabled = false;
      }
    } else {
      if (mobileSubmitBtn) {
        mobileSubmitBtn.disabled = true;
      }
    }
  }

  //Insert new content to main body of modal
  insertToMainBody(element, mainBodyStyle) {
    const mainBody = document.querySelector("#mainBody");
    if (mainBodyStyle) {
      //Custom style for main body wrapper
      for (let style in mainBodyStyle) {
        mainBody.style[style] = mainBodyStyle[style];
      }
    }
    while (mainBody.firstChild) {
      //Clean up all children elements inside main body
      mainBody.removeChild(mainBody.firstChild);
    }
    //Insert the new content
    mainBody.insertAdjacentHTML("beforeend", element);
  }

  //Insert new content to main content of top message box
  insertToTopMessageBox(element, messageBoxStyle) {
    const topMessageBox = document.querySelector("#topMessageBox");
    if (messageBoxStyle) {
      //Custom style for top message box
      for (let style in messageBoxStyle) {
        topMessageBox.style[style] = messageBoxStyle[style];
      }
    }
    while (topMessageBox.firstChild) {
      //Clean up all children elements inside top message box
      topMessageBox.removeChild(topMessageBox.firstChild);
    }
    //Insert the new content
    topMessageBox.insertAdjacentHTML("beforeend", element);
  }

  // Enter phone number to begin verification
  insertStepEnterYourPhone() {
    window.hasHandleSubmit = false;

    const modalBody = document.querySelector(".kyc-modal-body");
    // if(modalBody)
    modalBody.classList.remove("kyc-modal-verified-fail-body");

    const self = this;
    let mainBodyContent = `
    <div id="startVerification" style="margin-top: 48px">
      <h3>Start Verification</h3>
       <form id="phoneNumberForm" class="kyc-form">
                  <div class="input_tel">
                    <input
                      id="phoneTel"
                      type="tel"
                      name="phoneTel"
                      placeholder="Mobile number"
                    />
                    <button disabled type="submit" id="submit" name="submit" class="kyc-submit-btn">
                      ${self.domainConfig.buttonName || Icons.LONG_RIGHT_ARROW}
                    </button>
                  </div>
        </form>
    </div>
    `;
    //If returning from step 2
    document.querySelector("#step2")?.classList.remove("active");
    document.querySelector("#step1")?.classList.remove("completed");
    document.querySelector("#step1")?.classList.add("active");
    document.querySelector("#step3")?.classList.remove("active");
    document.querySelector("#step3")?.classList.remove("completed");

    self.insertToMainBody(mainBodyContent);

    //If verification fails and user wants to try again
    //Navigate back from verification failed screen
    let closeBtn = document.querySelector("#closeBtn");
    //Clean up event listener
    if (closeBtn) {
      closeBtn.removeEventListener("click", () => {
        self.handleCloseModal();
      });
      //Remove close button
      closeBtn.remove();
    }

    //Display verification step status after return from verification fail
    if (!document.querySelector("#stepProgressBar")) {
      const stepProgressBarElement = `
      <div class="kyc-steps" id="stepProgressBar">
        <div class="stepper-wrapper">
          <div class="stepper-item active" id="step1">
            <div class="step-counter">1</div>
          </div>
          <div class="stepper-item" id="step2">
            <div class="step-counter">2</div>
          </div>
          <div class="stepper-item" id="step3">
            <div class="step-counter">3</div>
          </div>
        </div>
      </div>
    `;
      document
        .querySelector(".kyc-modal-body")
        .insertAdjacentHTML("afterbegin", stepProgressBarElement);
    }

    //If navigate back from check your phone screen
    //When users want to edit phone number and resend link
    let clickHereBtn = document.querySelector("#clickHereBtn");
    if (clickHereBtn) {
      //If navigate back from check your phone screen
      clickHereBtn.removeEventListener("click", () => () => {
        self.insertStepEnterYourPhone();
        clearInterval(window.statusInterval);
      });
    }
    //Display top message box again if navigated back from verified fail screen
    if (document.querySelector("#topMessageBox").style.display === "none") {
      document.querySelector("#topMessageBox").style.display = "grid";
    }
    let topMessageBoxContent = `
    <div id="messageFromLLC">
      <div class="kyc-tooltip-icon-wrapper">
        <a href="javascript:void(0);" class="kyc-tooltip-icon">
          ${Icons.INFO_TOOLTIP}
          <div class="kyc-tooltip-text">
            <span class="kyc-hightlight">
              You must have a valid government-issued ID.
            </span>
            <span>
              Your info will be used to verify your identity and will be shared with ${self.domainConfig.partner.businessName
      }.  Next time, you can skip this  step!
            </span>
          </div>
        </a>
      </div>
      <div class="kyc-message-body">
        <h5>A Message from ${self.domainConfig.partner.businessName}</h5>
        <p>${self.domainConfig.openingMsg ||
      "Age verification is required by FDA and state regulations. Most customers can be verified in less than a minute."
      }</p>
      </div>
    </div>
    `;
    self.insertToTopMessageBox(topMessageBoxContent);
    // Reattach event handlers to form
    let form = document.getElementById("phoneNumberForm");
    if (typeof form !== "undefined" && form !== null) {
      if (!window.hasHandleSubmit) {
        window.hasHandleSubmit = true;
        form.addEventListener("submit", (event) => {
          self.handleSubmit(event);
        }); // Handle submit button
      }
    }
  }

  // Check your phone after submit phone number
  insertStepCheckYourPhone() {
    const self = this;
    const closeBtn = document.querySelector("#closeBtn");
    closeBtn && closeBtn.remove();
    //Display verification step status after return from verification fail
    if (!document.querySelector("#stepProgressBar")) {
      const stepProgressBarElement = `
      <div class="kyc-steps" id="stepProgressBar">
        <div class="stepper-wrapper">
          <div class="stepper-item active" id="step1">
            <div class="step-counter">1</div>
          </div>
          <div class="stepper-item" id="step2">
            <div class="step-counter">2</div>
          </div>
          <div class="stepper-item" id="step3">
            <div class="step-counter">3</div>
          </div>
        </div>
      </div>
    `;
      document
        .querySelector("#logo_verification")
        .insertAdjacentHTML("afterend", stepProgressBarElement);
    }
    let mainBodyContent = `
    <div id="checkYourPhone" style="margin-top: 50px; text-align: center">
      <div class="kyc-loader">
        <span class="loader__dot">${Icons.LOADING_DOT}</span>
        <span class="loader__dot">${Icons.LOADING_DOT}</span>
        <span class="loader__dot">${Icons.LOADING_DOT}</span>
      </div>
      <h2 class="kyc-complete-verification-text">Complete verification on <span>your phone</span></h2>
      <h2 class="kyc-mobile-complete-verification-text">Complete verification <span>using link</span></h2>
    </div>
      `;
    self.insertToMainBody(mainBodyContent);
    self.isRetry &&
      (() => {
        document.querySelector("#topMessageBox").style.display = "grid";
      })();
    document.querySelector("#step1")?.classList.remove("active");
    document.querySelector("#step1")?.classList.add("completed");
    document.querySelector("#step2")?.classList.add("active");

    let topMessageBoxContent = `
    <div id="linkSentToPhone">
      <p class="kyc-link-sent-text">
        We&#39;ve sent a link to <span> +${dialCode} (${self.numberEntered.substring(
      0,
      3
    )}) ${self.numberEntered.substring(3, 6)}-${self.numberEntered.substring(
      6
    )}</span>. Click <button id="clickHereBtn"><span>here</span></button> to edit or resend the link.
        This window will close automatically when you&#39;re done
      </p>
    </div>
    `;
    self.insertToTopMessageBox(topMessageBoxContent);
    document.querySelector("#clickHereBtn").addEventListener("click", () => {
      clearInterval(window.statusInterval);
      self.insertStepEnterYourPhone();
      addJsForPhoneTelInput();
    });
  }

  // Insert verified success screen if verification is success
  insertVerifiedSuccess() {
    let mainBodyContent = `
    <div id="verifiedSuccess">
      <div class="kyc-verified-message">
        <div class="kyc-success-icon">${Icons.SUCCESS}</div>
        <p>Your verification <span>was successful</span></p>
      </div>
    </div>
    `;
    this.insertToMainBody(mainBodyContent);

    document.querySelector("#step2").classList.remove("active");
    document.querySelector("#step2").classList.add("completed");

    document.querySelector("#step3").classList.add("active");

    let topMessageBoxContent = `
    <div id="messageFromLLC">
      <div class="kyc-message-body" style="width: 410px">
        <h5>A Message from ${this.domainConfig.partner.businessName}</h5>
        <p style="margin: 9px 0 0">${this.domainConfig.successMsg ||
      "Thanks for verifying your age. You can continue your purchase."
      }</p>
      </div>
    </div>
    `;
    this.insertToTopMessageBox(topMessageBoxContent);
  }

  fetchDataRetry = () => {
    const self = this;
    self.fetchApiVerify(self.numberEntered, self);
  };

  // Insert verified failure screen if verification failed
  insertVerifiedFailure() {
    const self = this;
    const modalBody = document.querySelector(".kyc-modal-body");
    modalBody.classList.add("kyc-modal-verified-fail-body");
    let mainBodyContent = `
    <div id="verifiedFail">
      <div class="kyc-fail-message">
        <div class="kyc-fail-icon">${Icons.FAIL}</div>
        <h3>Your verification <span>was not successful</span></h3>
        <p>This could be because you don&#39;t meet the age requirement or because your document did not scan properly.</p>
        <button class="kyc-try-again-btn" id="tryAgain">TRY AGAIN</button>
      </div>
    </div>
    `;
    self.insertToMainBody(mainBodyContent);
    const retryBtn = document.querySelector("#tryAgain");
    retryBtn.addEventListener("click", () => {
      self.isRetry = true;
      self.fetchDataRetry();
    });

    self.isRetry &&
      (() => {
        retryBtn.disabled = true;
        setTimeout(() => {
          self.handleCloseModal();
          retryBtn.disabled = false;
        }, 5000);
        clearInterval(window.statusInterval);
      })();

    //Handle close button
    const stepProgressBar = document.querySelector("#stepProgressBar");
    let closeBtn = `
    <button class="kyc-close-btn" id="closeBtn">${Icons.DARK_X_BUTTON}</button>
    `;
    document
      .querySelector(".kyc-modal-body")
      .insertAdjacentHTML("afterbegin", closeBtn);
    document.querySelector("#closeBtn").addEventListener("click", () => {
      self.handleCloseModal();
    });
    //Remove step status and top message box
    stepProgressBar?.remove();
    document.querySelector("#topMessageBox").style.display = "none";
  }

  //Insert modal to dom
  insertModalToDOM(domainConfig) {
    let element = `
    <div id="verificationScript">
      <div id="dentity-Modal" class="modal">
        <!-- Modal content -->
        <div class="kyc-modal-content">
          <div class="kyc-top-message-box kyc-card" id="topMessageBox">
            <div id="messageFromLLC">
              <div class="kyc-tooltip-icon-wrapper">
                <a href="javascript:void(0);" class="kyc-tooltip-icon" >
                  ${Icons.INFO_TOOLTIP}
                  <div class="kyc-tooltip-text">
                    <span class="kyc-hightlight">
                      You must have a valid government-issued ID.
                    </span>
                    <span>
                      Your info will be used to verify your identity and will be shared with ${domainConfig.partner.businessName
      }.  Next time, you can skip this  step!
                    </span>
                  </div>
                </a>
              </div>
              <div class="kyc-message-body">
                <h5>A Message from ${domainConfig.partner.businessName}</h5>
                <p>${domainConfig.openingMsg ||
      "Age verification is required by FDA and state regulations. Most customers can be verified in less than a minute."
      }</p>
              </div>
            </div>
          </div>
          <div class="kyc-modal-body kyc-card">
            <img src="" alt="logo" id="logo_verification">
            <div class="kyc-steps" id="stepProgressBar">
              <div class="stepper-wrapper">
                <div class="stepper-item active mobile-active" id="step1">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item" id="step2">
                  <div class="step-counter">2</div>
                </div>
                <div class="stepper-item" id="step3">
                  <div class="step-counter">3</div>
                </div>
              </div>
            </div>
            <div class="kyc-main-body" id="mainBody">
              <div id="startVerification" style="margin-top: 48px">
                <h3>Start Verification</h3>
                <form id="phoneNumberForm" class="kyc-form">
                  <div class="input_tel">
                    <input
                      id="phoneTel"
                      type="tel"
                      name="phoneTel"
                      placeholder="Mobile number"
                    />
                    <button disabled type="submit" id="submit" name="submit" class="kyc-submit-btn">
                      ${domainConfig.buttonName || Icons.LONG_RIGHT_ARROW}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="kyc-logo-wrapper">
              <span class="kyc-logo-text">Powered by </span>
              ${Icons.LOGO_2}
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
    document.querySelector("body").insertAdjacentHTML("beforeend", element);
    addJsForPhoneTelInput();
  }

  // Display modal
  handleShowModal() {
    const self = this;
    let modal = document.getElementById("dentity-Modal");
    let logo = document.getElementById("logo_verification");
    logo.src = `${config.BASE_IMG_URL}/${self.domainConfig.logo}/medium`;
    if (!document.querySelector("#startVerification")) {
      this.insertStepEnterYourPhone();
    }
    if (typeof modal !== "undefined" && modal !== null) {
      // Check if modal is successfully inserted
      // Close button closes the modal
      let closeBtn = document.getElementById("kyc-close");
      if (typeof closeBtn !== "undefined" && closeBtn !== null) {
        closeBtn.addEventListener("click", (event) => {
          self.handleCloseModal(event);
        }); // Handle close button
      }
      let form = document.getElementById("phoneNumberForm");
      if (typeof form !== "undefined" && form !== null) {
        if (!window.hasHandleSubmit) {
          window.hasHandleSubmit = true;
          form.addEventListener("submit", (event) => {
            self.handleSubmit(event);
          }); // Handle submit button
        }
      }
      modal.style.display = "block";
      this.dispatchShow();
    }
  }

  fetchApiVerify(phoneNumber, self) {
    fetch(API_VERIFICATION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: this.config.key,
        phoneNumber: {
          dialCode: `+${dialCode}`,
          phone: phoneNumber,
        },
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        return response.json().then((json) => {
          throw new Error(json.message);
        });
      })
      .then((result) => {
        // Go to check your phone screen
        const form = document.querySelector("#phoneNumberForm");
        if (form) {
          //Clean up event listeners
          form.removeEventListener("submit", (event) => {
            self.handleSubmit(event);
          });
        }
        self.insertStepCheckYourPhone();
        self.getStatus(result?.data?._id);
        self.dispatchSubmitted(result.data);
      })
      .catch((error) => {
        self.dispatchSubmitted(null, error);
        const errorText = document.querySelector(".kyc-error");
        errorText.innerHTML = error;
        errorText.style.display = "block";
      });
  }

  // Submit form
  handleSubmit(event) {
    event.preventDefault();
    const self = this;
    const phoneInputField = document.querySelector("#phoneTel");
    const value = formatPhoneNumber(phoneInputField.value);
    const number = revertPhoneFormatToString(value);
    this.dispatchSubmit({ dialCode: dialCode, number });
    self.numberEntered = number;
    self.fetchApiVerify(number, self);
  }

  //Get verification status every second until status is either verified or failed
  getStatus(verificationId) {
    const self = this;
    clearInterval(window.statusInterval);
    //Set a global interval to keep track of status
    window.statusInterval = setInterval(() => {
      fetch(API_VERIFICATION + "/" + verificationId + "/status", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": this.config.key,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          // If verification status is verified, clear the interval and return data
          if (result.data.status === VerificationStatus.VERIFIED) {
            this.dispatchChangeStatus(result.data);
            //Go to verified success screen
            this.insertVerifiedSuccess();
            clearInterval(window.statusInterval);
            setTimeout(() => {
              self.handleCloseModal();
            }, 5000);
          }
          // If verification status is failed, clear the interval and return data
          else if (result.data.status === VerificationStatus.FAILED) {
            this.dispatchChangeStatus(result.data);
            //Go to verified failed screen
            this.insertVerifiedFailure();
            clearInterval(window.statusInterval);
          }
        })
        .catch((error) => {
          //If error occurs, clear the interval
          this.insertVerifiedFailure();
          clearInterval(window.statusInterval);
          console.log(error);
        });
    }, 1000);
  }

  //Get config website based on apiKey
  getConfigWebsite() {
    return new Promise((resolve, reject) => {
      fetch(API_WEBSITE + "/" + this.config.key, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Hide modal
  handleCloseModal() {
    let modal = document.getElementById("dentity-Modal");
    modal.style.display = "none";

    //Reset step status on closing modal
    document.querySelector("#step2")?.classList.remove("active");
    document.querySelector("#step2")?.classList.remove("completed");
    document.querySelector("#step3")?.classList.remove("active");
    document.querySelector("#step3")?.classList.remove("completed");

    clearInterval(window.statusInterval);
    this.isRetry = false;
    this.dispatchHide();
    if (mobileCheck()) {
      window.removeEventListener("touchstart", function (event) {
        const tooltip = this.document.querySelector(".kyc-tooltip-icon");
        if (
          event.target !== tooltip &&
          tooltip &&
          this.document.activeElement === tooltip
        ) {
          tooltip.blur();
        }
      });
    }
  }

  //Handle on event, takes in type of event and handler
  onEvent(event = "", handler) {
    if (this.eventMap.has(event)) {
      this.eventMap.set(event, this.eventMap.get(event).concat([handler]));
    } else {
      this.eventMap.set(event, [handler]);
    }
  }

  //Handle dispatching event, takes in type of event and data
  dispatchEvent(event = "", data) {
    if (this.eventMap.has(event)) {
      const handlers = this.eventMap.get(event);
      for (const i in handlers) {
        handlers[i](data);
      }
    }
  }

  //A function called when the script has loaded and initialized
  onReady(handler) {
    this.onEvent("onReady", handler);
  }

  dispatchReady() {
    this.dispatchEvent("onReady");
  }

  //A function called when the verification popup is displayed
  onShow(handler) {
    this.onEvent("onShow", handler);
  }

  dispatchShow() {
    this.dispatchEvent("onShow");
  }

  //A function called when the verification popup is closed (hidden) before the verification is submitted
  onHide(handler) {
    this.onEvent("onHide", handler);
  }

  dispatchHide() {
    this.dispatchEvent("onHide");
  }

  //A function for adding custom behavior to the popup
  onChangeStatus(handler) {
    this.onEvent("onChangeStatus", handler);
  }

  dispatchChangeStatus(data) {
    this.dispatchEvent("onChangeStatus", data);
  }

  //A function called on pre submit
  onSubmit(handler) {
    this.onEvent("onSubmit", handler);
  }

  dispatchSubmit(data) {
    this.dispatchEvent("onSubmit", data);
  }

  //A function called when submitted
  onSubmitted(handler) {
    this.onEvent("success", handler);
  }

  dispatchSubmitted(data, err) {
    this.dispatchEvent("success", data, err);
  }
}

export default Dentity;
